#cardCarousel-container{
    display: flex;
    width: 50vw;
    height:48vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 10px solid white; */
    /* background-color: aqua; */
}

/* #cardCarousel-container .carousel.carousel-slider {
    width: 45vw!important;
} */

.carousel.carousel-slider {
    width: 45vw!important;
}

#cardCarousel-container .carousel .carousel-status{
    display: none;
} 

.carousel-control-prev, .carousel-control-next{
    display: none;
} 

/* .card-display{ */
    /* height:48vh !important; e*/
    /* height:100% !important; e*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: #0e0e0e; */
    /* background-image: url(''); */
    /* background-size: 100% 150% !important;e
    background-position: center !important; e*/
    /* background-repeat: no-repeat; */
    /* color: white; */
    /* word-wrap: break-word; */
    /* border: 10px solid blue;e */
    /* height:46vh; */
    /* width: 45vw;; e*/
/* } */

.card-display {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0e0e0e;
    background-image: url('');
    background-size: 100% 150% !important;
    background-position: center center !important;
    background-repeat: no-repeat;
    color: white;
    width: 100%;
    /* height: 100%; */
    height:46vh;
    word-wrap: break-word;
}

#play-pause-button{
    background-color: #259FB1;
    border-color: #259FB1;
    border-radius: 0px;
    width: 30%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    column-gap: 10%;
}

/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

    #cardCarousel-container{
        width: 100%;
    }

    #cardCarousel-container .carousel.carousel-slider .card-display {
        /* width: 80vw!important; */
        /* width: 70vw; */
        height: 30vh;
        margin-bottom: 10px;
    }

    .carousel {
        width: 80vw;
    }

}
